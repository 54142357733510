import { CmsGuideLayout } from "../../../components/CmsGuideLayout"
import { lorem } from "../../../lib/lorem"
import { PageDataBodyIllustrationsCtaS } from "../../../slices/PageDataBodyIllustrationsCtas"

type Card = React.ComponentPropsWithoutRef<
	typeof PageDataBodyIllustrationsCtaS
>["cards"][number]

const card = (data: Partial<Card> = {}): Card => {
	return {
		heading: lorem.words(1),
		imgUrl: "",
		imgAlt: "",
		imgDimensions: undefined,
		copy: [
			{
				type: "paragraph",
				text: lorem.sentences(2),
				spans: [],
			},
		],
		link: undefined,
		linkText: undefined,
		...data,
	}
}

const Component = () => {
	return (
		<CmsGuideLayout>
			<PageDataBodyIllustrationsCtaS
				heading={lorem.words(4)}
				buttonLink="#"
				buttonText="Learn More"
				cards={[
					card({
						imgUrl:
							"https://rush-to-crush-cancer.cdn.prismic.io/rush-to-crush-cancer/85e8093d-e9c0-4ede-b131-2fae4e98e321_Group%2036%20Copy.svg?ixlib=gatsbyFP&auto=compress%2Cformat&fit=max",
						imgDimensions: {
							width: 151,
							height: 148,
						},
					}),
					card({
						imgUrl:
							"https://rush-to-crush-cancer.cdn.prismic.io/rush-to-crush-cancer/de2ab932-2b65-46a9-8af3-3aba8699608e_Group%2050%20Copy.svg?ixlib=gatsbyFP&auto=compress%2Cformat&fit=max",
						imgDimensions: {
							width: 146,
							height: 138,
						},
						link: "#",
						linkText: lorem.words(2),
					}),
					card({
						heading: lorem.words(1),
						imgUrl:
							"https://rush-to-crush-cancer.cdn.prismic.io/rush-to-crush-cancer/88858cb3-59f4-4a01-8b1d-efa332da9adf_Group%2076%20Copy.svg?ixlib=gatsbyFP&auto=compress%2Cformat&fit=max",
						imgDimensions: {
							width: 133,
							height: 135,
						},
					}),
				]}
			/>
		</CmsGuideLayout>
	)
}

export default Component
